import * as Yup from 'yup';

import { FunctionComponent, ReactElement } from "react";
import { Button, Typography } from 'antd'
import {Form} from "../../../components/form"
import {Col, Row} from 'antd'

import { ARIFieldTextArea } from "components/form/ariFieldTextArea";
import { ARIFieldSelect } from "components/form/ariFieldSelect";
import { useAppDispatch } from 'config/store/hooks';
import { ModalAction } from 'modules/ui/actions';
import { MODALS } from 'models/ui';

const { Paragraph, Title } = Typography

const validationSchema = Yup.object().shape({
  // likeMostExperience: Yup.string(),
  // improve: Yup.string(),
  // comments: Yup.string(),
  // recommend: Yup.number(),
}) 

interface Props {
  next: (values: any) => void; 
  previous: () => void
}

export const FormStepTrhee: FunctionComponent<Props> = ({next, previous}): ReactElement => {
  const dispatch = useAppDispatch()
  const _handleSubmit = (values: any) => {
    console.log(values)
    dispatch(ModalAction.openModal({name: MODALS.TALENT_PROMOTION_PERSONAL_INFORMATION}))
  }

  return (
    <Form
      handleSubmit={_handleSubmit}
      validationSchema={validationSchema}
    >
      <div>
        <Title level={3}> 3. COMENTARIS</Title>
        <Paragraph style={{color: 'grey'}}> -OPCIONAL- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie mi leo, ut tincidunt eros ultricies eget. Maecenas dictum nibh magna, at rutrum est pellentesque non. Integer facilisis dolor in est accumsan dictum. Cras dignissim ligula non metus lacinia varius. Integer molestie, augue nec tempor vulputate, tortor massa dapibus leo, in aliquam arcu erat sed velit. Cras aliquam rhoncus massa, ac luctus tellus mattis nec. Aenean vestibulum consequat tortor, sed tincidunt quam pretium eu. Quisque cursus mollis hendrerit. </Paragraph>
        <Row gutter={16}>
          <Col sm={12} xs={24}>
            <ARIFieldTextArea
              label="Què t'ha agradat més de la teva experiència?"
              name="likeMostExperience"
              rows={2}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ARIFieldTextArea
              label="Què creus que es podria millorar?"
              name="improve"
              rows={2}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ARIFieldTextArea
              label="Has rebut algun tracte que es pugui qualificar de discriminatori, vexatori, assetjament, etc?"
              name="inclusive"
              rows={1}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ARIFieldTextArea
              label="Tens algun altre comentari o sugeriment?"
              name="comments"
              rows={2}
            />
          </Col>
          <Col sm={12} xs={24}>
            <ARIFieldSelect              
              label="De l'1 al 10 com recomanaries fer pràctiques a l'AR+I als teus amics o familiars?"
              name="recommend"
              items={[{
                label: '1',
                value: '1'
              },{
                label: '2',
                value: '2'
              },
              {
                label: '3',
                value: '3'
              },{
                label: '4',
                value: '4'
              },
              {
                label: '5',
                value: '5'
              },{
                label: '6',
                value: '6'
              },{
                label: '7',
                value: '7'
              },{
                label: '8',
                value: '8'
              },{
                label: '9',
                value: '9'
              },{
                label: '10',
                value: '10'
              }]}
            />
          </Col>
        </Row>

        <Row style={{marginTop: '3%'}}>
          <Col span={12}>
            <Button onClick={previous} style={{width: '100px'}}> Anterior </Button>
          </Col>
          <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button htmlType="submit" style={{width: '100px'}}><strong>Finalitzar</strong></Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}