
import { ReactElement, FunctionComponent, ReactNode } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { utilsService } from 'services/utilsServices';
import dayjs from 'dayjs'; //TODO: serraCarles: Migrar a moment con el utilsServices

import { DatePicker, Typography } from "antd";
import { FORMAT } from "models/ui";

const { Text, Paragraph } = Typography

type Props = {
  name: string
  label?: string
  dateFormat?: string
  description?: string
  defaultValue?: string
  placeHolder?: string
  children?: ReactNode
  mandatory?: boolean
  maxDate?: any
  onChange?: (value?: any) => void;
};

export const ARIFieldDatePicker: FunctionComponent<Props> = ({
  name,
  label,
  description, 
  defaultValue,
  placeHolder,
  mandatory,
  dateFormat,
  maxDate,
  ...props
}): ReactElement => {
  const { control, formState: { errors } } = useFormContext();
  // TODO: serraCarles: Verify lodash librery to simplify this code
  const getError = (name: string, errors: any) => {
    const keys = name.split('.');
    let error = errors;
    for (const key of keys) {
      if (error[key]) {
        error = error[key];
      } else {
        return undefined;
      }
    }
    return error.message;
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, onBlur } }) => (
        <div style={{marginTop: '2%'}}>
          <Text><strong>{`${label}`}</strong>{mandatory && ('*')}</Text>
          {description && (<Paragraph style={{color:'grey'}}> {`${description}`} </Paragraph>)}
          <DatePicker 
            defaultValue={defaultValue && dayjs((defaultValue), dateFormat)} 
            format={dateFormat ? dateFormat : FORMAT.YYYY_MM_DD } 
            style={{ width: '100%' }}
            variant="filled"
            maxDate={maxDate && maxDate}
            placeholder={placeHolder && placeHolder}
            onChange={value => {
              value && 
                onChange(utilsService.formatDate(value.toString(), FORMAT.YYYY_MM_DD))
                props.onChange && props.onChange(utilsService.formatDate(value.toString(), FORMAT.YYYY_MM_DD))
            }}
          />
        {getError(name, errors) && <Text style={{ color: 'red' }}>{getError(name, errors)}</Text>}
        </div>
      )}
    />
  );
};
