
import { ARIModals } from "components/modal";
import { FORMAT, MODALS } from "models/ui";
import { FunctionComponent, ReactNode } from "react";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useAppSelector } from "config/store/hooks";
import { selectCurrentMetadata } from "modules/ui/selector";
import { utilsService } from "services/utilsServices";

type Props = {
  children?: ReactNode
}

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Esto coloca una imagen a la izquierda y otra a la derecha
    marginBottom: 20,
  },
  regularText: {
    fontSize: 12, // Tamaño de texto normal
    color: '#000000', // Color del texto normal
    lineHeight: 1.5, // Altura de línea
    textAlign: 'justify'
  },
  boldText: {
    fontSize: 12, // Tamaño de texto normal
    fontWeight: 'bold'
  },
  signature: {
    position: 'relative',
    width: '100px'
  },
  logo: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '150px',
    marginTop: '7%',
    marginLeft: '7%'
    // height: '200px',
  },
});

const MyDocument = (metadata: any) => {  
  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src="jpeg/ARIicon.png"/>
        <Image style={styles.logo} src="jpeg/letterResource.png"/>  
      </View>
      <View>
        <div style={{marginLeft: '14%', marginTop: '10%', marginRight: '10%'}}>
          {/* <Text style={styles.regularText}>{ `Dear Mr./Mrs. ${metadata.metadata.surname} ,` }</Text> */}
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>
              Mitjançant la present, certifiquem que l'estudiant <Text style={styles.boldText}>{metadata.metadata.value.name}</Text> ha realitzat satisfactòriament el programa de Promoció del Talent a Andorra Recerca i Innovació durant el període comprès entre <Text style={styles.boldText}>{utilsService.formatDate(metadata.metadata.value.intershipsDates.start, FORMAT.D_MMMM_YYYY)}</Text> i el <Text style={styles.boldText}>{utilsService.formatDate(metadata.metadata.value.intershipsDates.end, FORMAT.D_MMMM_YYYY)}</Text>.
            </Text>    
          </div>
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>{`El programa de Foment del Talent és una iniciativa/oportunitat que es promou des d'Andorra Recerca i Innovació per tal de promoure el coneixement que es desenvolupa internament en tots els àmbits i que permet la realització de pràctiques, el treball temporal o altres.... [A COMPLETAR]`}</Text>    
          </div>
          <div style={{marginTop: '2%'}}>
            <Text style={styles.regularText}>{`[ COMPLETAR ]indicar aspectes d'AR+I: Andorra Recerca i Innovació és una fundació privada del sector públic amb número de registre 00000 que`}</Text>
          </div>
          <div style={{ marginLeft: '50%'}}>
            <Image 
              style={styles.signature}
              src="jpeg/VanesaSignature.png"
            />
            <Text style={styles.regularText}>Atentament,</Text>
            <Text style={styles.regularText}>Vanesa Arroyo</Text>
            <Text style={styles.regularText}>Gerenta d'Andorra Recerca + Innovació</Text>
          </div>
        </div>
      </View>
    </Page>
  </Document>
)
};

export const ModalTalentPromotionDocument: FunctionComponent<Props> = ({children}) => {
  const metadata = useAppSelector(selectCurrentMetadata)
  return(
    <ARIModals name={MODALS.TALENT_PROMOTION_DOCUMENT} drawer={{slide: 'right' }} size="75%">
      <PDFViewer width="100%" height={"100%"}>
        <MyDocument metadata={metadata}/>
      </PDFViewer>
    </ARIModals>
  )
}