import { FunctionComponent, ReactElement, ReactNode } from "react"
import { ModalRegistrationConfirm } from "./cityScienceSummit/modalRegistrationConfirm";
import { ModalRegistrationError } from "./cityScienceSummit/modalRegistrationError";
import { ModalInvitationalLetter } from "./cityScienceSummit/modalInvitationalLetter";
import { ModalTalentPromotionCertificate } from "./intershipsAvaliation/talentPromotionCertificate";
import { ModalTalentPromotionPersonalInformation } from "./intershipsAvaliation/talentPromotionPersonalInformation";
import { ModalTalentPromotionDocument } from "./intershipsAvaliation/talentPromotionDocument";

type Props = {
  children?: ReactNode;
};

export const GlobalModals: FunctionComponent<Props> = ({
  children,
}): ReactElement => {
  return (
    <>
      {/* CITY SCIENCE SUMMIT */}
      <ModalRegistrationConfirm />
      <ModalRegistrationError />
      <ModalInvitationalLetter />

      {/* INTERSHIPS AVALUATION */}
      <ModalTalentPromotionDocument />
      <ModalTalentPromotionCertificate />
      <ModalTalentPromotionPersonalInformation />
    </>
  );
};
